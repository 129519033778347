.profile-container {
    width: 100%;
    height: 93vh;
}

.profile-container .profile-header {
    width: 100%;
    height: 5vh;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    background-color: #c6e2fa;
    background-color: #edf5ff;
    padding-left: 1%;
    /* padding-bottom: 0.25vh; */
    /* border-bottom: 1px solid #373737; */


}



.profile-container .profile-header .navigation {
    margin-top: 0.5vh;
    width: 7%;
    text-align: center; 
    cursor: pointer;
    margin-right: 1%;
}

.profile-container .profile-header .selected {

    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 2px solid #f29219;
    /* color: #f29219; */
    /* font-weight: bold; */
}

.profile-container .profile-contents {
    width: 100%;
    height: 88vh;
}

.profile-container .profile-contents .services-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-container .profile-contents .services-container .profile-info {
    width: 36%;
    height: 88vh;
    border-right: 1px solid lightgray;
    padding-left: 2%;
    padding-right: 2%;
    padding-top:  5vh;
    padding-bottom: 5vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: justify;

}

.profile-container .profile-contents .services-container .profile-info .profile-image img {
    border-radius: 50%;
}

.profile-container .profile-contents .services-container .profile-info .avgs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.profile-container .profile-contents .services-container .profile-info .avgs > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.profile-container .profile-contents .services-container .profile-info .avgs > div .icon {
    padding-right: 2%;
    color: #1878F1;
}

.profile-container .profile-contents .services-container .profile-info .avgs > div .value-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.profile-container .profile-contents .services-container .profile-info .avgs > div .value-text .text {
    font-size: 0.8em;
    color: gray;
    text-align: center;
}

.profile-container .profile-contents .services-container .services {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding-left: 2%;
    padding-right: 2%;
}

/* .profile-container .profile-contents .services-container .story-service .coming-soon {
    left: 44%;
} */

/* .profile-container .profile-contents .services-container .coming-soon {
    position: absolute; 
    background-color: black;
    width: 25%; 
    height: 60vh;
    top: 22vh;
    left: 74%;
    z-index: 100;
    border-radius: 10px;
    opacity: 0.5;
} */

.profile-container .profile-contents .services-container .services .services-title {
    width: 100%;
    height: 7vh;
    line-height: 7vh;
    font-size: 1.3em;
}

.profile-container .profile-contents .services-container .services .services-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border: 1px solid lightgray;
    border-radius: 15px;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1vh;
    padding-bottom: 1vh;
    margin-bottom: 3vh;
}

.profile-container .profile-contents .services-container .services .services-nav .nav {
    width: 10%;
    min-width: 100px;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}

.profile-container .profile-contents .services-container .services .services-nav .selected {
    background-color: #c6e2fa;
}

.profile-container .profile-contents .services-container .services .services-content {
    width: 100%;
    height: 71vh;

    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}

.profile-container .profile-contents .services-container .services .services-content .content {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: flex-start;
    flex-wrap: wrap;
}

.profile-container .profile-contents .services-container .services .services-content .service {
    width: 32%;
    min-width: 330px;
    height: 50vh;
    border: 1px solid lightgray;
    border-radius: 15px;
    margin-bottom: 2vh;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1vh;
    padding-bottom: 1vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    cursor: pointer;

    margin-right: 2%;
}

.profile-container .profile-contents .services-container .services .services-content .service .service-title {
    width: 100%;
    height: 4vh;
    line-height: 4vh;
    font-weight: bold;
    font-size: 1.2em;
}

.profile-container .profile-contents .services-container .services .services-content .service .service-description {
    width: 100%;
    height: 10vh;
    padding-top: 0.5vh;
    color: #1878F1;
} 

.profile-container .profile-contents .services-container .services .services-content .service .service-content-provider {
            
}

.profile-container .profile-contents .services-container .services .services-content .service .service-content-provider .title,
.profile-container .profile-contents .services-container .services .services-content .service .pricing-type .title {
    color: gray;
}

.profile-container .profile-contents .services-container .services .services-content .service .option-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.profile-container .profile-contents .services-container .services .services-content .service .option-1,
.profile-container .profile-contents .services-container .services .services-content .service .option-2 {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.profile-container .profile-contents .services-container .services .services-content .service .option label {
    padding-right: 5%;
}

.profile-container .profile-contents .services-container .services .services-content .service .option input {
    accent-color: #1878F1; 
    width: 17px;
    height: 17px;
    
}

.profile-container .profile-contents .services-container .services .services-content .service .post-length, 
.profile-container .profile-contents .services-container .services .services-content .service .price,
.profile-container .profile-contents .services-container .services .services-content .service .post-type {
    width: 100%;
    height: 6vh;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.profile-container .profile-contents .services-container .services .services-content .service .post-length span, 
.profile-container .profile-contents .services-container .services .services-content .service .price span,
.profile-container .profile-contents .services-container .services .services-content .service .post-type span {
    width: 10%;
    margin-right: 5%;
    color: #1878F1;
}

.profile-container .profile-contents .services-container .services .services-content .service .post-length p, 
.profile-container .profile-contents .services-container .services .services-content .service .price p,
.profile-container .profile-contents .services-container .services .services-content .service .post-type p {
    width: 90%;
}

.profile-container .profile-contents .services-container .services .services-content .service .price {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-container .profile-contents .services-container .services .services-content .service .price span,
.profile-container .profile-contents .services-container .services .services-content .service .price strong {
    margin-right: 2%;
}

.profile-container .profile-contents .services-container .services .services-content .service .price input {
    width: 55%;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: right;
}

.profile-container .profile-contents .services-container .services .services-content .service .request-service {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.profile-container .profile-contents .services-container .services .services-content .service .request-service button {
    background-color: #1878F1;
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 7px;
}

.profile-container .profile-contents .services-container .services .services-content .service .request-service button:hover {
    opacity: 0.8;
}

.profile-container .profile-contents .analytics {
    width: 100%;
    height: 88vh;
    padding-left: 2%;
    padding-right: 2%;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
}
.profile-container .profile-contents .analytics .analytics-engagement,
.profile-container .profile-contents .analytics .analytics-demographics {    
    scroll-snap-align: start;    

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: 88vh;
}

.profile-container .profile-contents .analytics .analytics-engagement .title,
.profile-container .profile-contents .analytics .analytics-demographics .title {
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    margin-top: 1vh;
    font-size: 1.2em;
    border-bottom: 2px solid #f29219;
}

.profile-container .profile-contents .analytics .analytics-engagement .sub-header,
.profile-container .profile-contents .analytics .analytics-demographics .sub-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

}

.profile-container .profile-contents .analytics .analytics-engagement .sub-header .filter,
.profile-container .profile-contents .analytics .analytics-demographics .sub-header .filter {
    margin-top: 1vh;
}


.profile-container .profile-contents .analytics .analytics-engagement .navigation,
.profile-container .profile-contents .analytics .analytics-demographics .navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    margin-bottom: 3vh;
    width: 80%;
}

.profile-container .profile-contents .analytics .analytics-engagement .navigation .nav,
.profile-container .profile-contents .analytics .analytics-demographics .navigation .nav {
    width: 13%;
    min-width: 140px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 10px;
    text-align: center;
    margin-top: 1vh;
    margin-right: 1%;
    color: #1878F1;
    border: 2px solid #1878F1;   
    cursor: pointer; 
}

.profile-container .profile-contents .analytics .analytics-engagement .navigation .nav:hover,
.profile-container .profile-contents .analytics .analytics-demographics .navigation .nav:hover {
    background-color: #1878F1;
    /* background-color: #83b6f8; */
    color: #fff;
}

.profile-container .profile-contents .analytics .analytics-demographics .navigation .nav {
    width: 14%;
}

.profile-container .profile-contents .analytics .navigation .nav.selected {
    background-color: #1878F1;
    color: #fff;
}
.profile-container .profile-contents .analytics .analytics-engagement .content {
    width: 100%;
    height: 80vh;
    
    display: flex;
    flex-direction: row; 
    justify-content: space-between; 
    flex-wrap: wrap;
}

.profile-container .profile-contents .analytics .analytics-engagement .content.youtube-channel-metrics {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .graph-container.hidden {
    visibility: hidden;    
}

.profile-container .profile-contents .analytics .analytics-engagement .content .graph-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    border: 0.5px solid lightgray;    
    width: 32.5%;
    height: 23vh;
    border-radius: 15px;

    padding-left: 1%;
    padding-right: 1%;
}

.profile-container .profile-contents .analytics .analytics-engagement .content.content.youtube-channel-metrics .graph-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    border: 0.5px solid lightgray;    
    width: 49%;
    height: 35vh;
    border-radius: 15px;

    padding-left: 1%;
    padding-right: 1%;

}

.profile-container .profile-contents .analytics .analytics-engagement .content .graph-container .title {
    border: none;
    font-size: 1em;
    height: 3.5vh;
    line-height: 3vh;
    
    border-bottom: 1px solid #1878F1;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .graph-container .graph {
    width: 100%;
    height: 19vh;
}


.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph .content {
    width: 70%;
    height: 19vh;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph .changes {
    width: 30%;
    height: 19vh;
    padding-top: 1vh;
    padding-bottom: 1vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph .changes .quantity {
    font-weight: bold;
    font-size: 1.2em;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph .changes .increase {
    color: #22da22;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .website-clicks .graph .changes .text {
    color: gray;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .sentiment .graph {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .sentiment .graph .content {
    width: 80%;
    height: 17vh;
    padding-top: 1vh;        
}

.profile-container .profile-contents .analytics .analytics-engagement .content .sentiment .graph .value {
    width: 20%;
    height: 17vh;
    line-height: 17vh;
    font-size: 1.5em;
    font-weight: bold;
}

.profile-container .profile-contents .analytics .analytics-engagement .content .follow-unfollow .graph {
    width: 80%;
    height: 17vh;
    padding-top: 1vh;        
}

.profile-container .profile-contents .analytics .analytics-demographics .content { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics,
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics, 
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics { 
    width: 32%;
    height: 70vh;
    border-radius: 10px;
    border: 1px solid lightgray;    

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .title,
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .title,
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .title {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    margin-top: 0;
    margin-bottom: 1vh;
    border-bottom: 1px solid lightgray;
    padding-left: 5%;
    padding-right: 5%;

    font-size: 0.9em;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content,
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content,
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content {
    width: 100%;
    height: 64vh;
    padding-left: 5%;
    padding-right: 5%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    border: none;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content .row,
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content .row,
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content .row {
    width: 100%;
    height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content .row:nth-child(1),
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content .row:nth-child(1),
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content .row:nth-child(1) {
    font-weight: bold;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content .row .column:nth-child(1),
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content .row .column:nth-child(1),
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content .row .column:nth-child(1) {
    width: 80%;
    line-height: 5vh;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content .row .column:nth-child(2),
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content .row .column:nth-child(2),
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content .row .column:nth-child(2) {
    width: 20%;
    line-height: 5vh;
    text-align: center;
}

.profile-container .profile-contents .analytics .analytics-demographics .content .follower-demographics .content .row:nth-child(1) .column:nth-child(2),
.profile-container .profile-contents .analytics .analytics-demographics .content .engaged-demographics .content .row:nth-child(1) .column:nth-child(2),
.profile-container .profile-contents .analytics .analytics-demographics .content .reached-demographics .content .row:nth-child(1) .column:nth-child(2) {
    background-color: #1878F1;
    color: #fff;
    border-radius: 15px;
}

.profile-container .profile-contents .analytics .analytics-demographics .age .follower-demographics .content {
    width: 100%;
} 


.profile-container .profile-contents .services-container {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
    padding-left: 1%;
    padding-right: 1%;
    height: 80vh;
}

.profile-container .profile-contents .services-container .service {
    width: 30%; 
    height: 60vh;
    border: 2px solid lightgray;
    border-radius: 5px;
    font-family: "Nunito";
}

.profile-container .profile-contents .services-container .service .service-header {
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5em;
    border-bottom: 1px solid lightgray;
    height: 7vh;
}

.profile-container .profile-contents .services-container .service .service-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 10px;
    padding-right: 10px;
}

.profile-container .profile-contents .services-container .service .service-content .content {
    width: 100%; 
    height: 13vh;
    /* padding-left: 10px;
    padding-right: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.profile-container .profile-contents .services-container .service .service-content .content.pricing-method {
    height: 23vh;
}

.profile-container .profile-contents .services-container .service .service-content .content.price {
    background: #eef3f9;
    height: 6vh;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
    color: #1878F1;
    font-size: 17px;
    font-weight: bold;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.profile-container .profile-contents .services-container .service .service-content .content.duration {
    background: #eef3f9;
    height: 6vh;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
    color: #1878F1;
    font-size: 17px;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 3vh;
    padding-left: 10px;
    padding-right: 10px;
}

.profile-container .profile-contents .services-container .service .service-content .content.duration input {
    width: 50%;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
}

.profile-container .profile-contents .services-container .service .service-content .content.price-display {
    text-align: center;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    height: 5vh;
    font-size: 20px;
    font-weight: bold;
    margin: 15px 0;
    color: #f29219;
    padding: 10px;
    background: #fff3e0;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-container .profile-contents .services-container .service .service-content .content.pricing-method .pricing-amount-header {
    margin-top: 1vh;
    margin-bottom: 0;
}

.profile-container .profile-contents .services-container .service .service-content .content .content-header {
    margin-bottom: 2vh;
    font-weight: bold;
}

.profile-container .profile-contents .services-container .service .service-content .content.pricing-method .pricing-amount input {
    border: 1px solid gray;
    outline-color: #1878F1;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
}

.profile-container .profile-contents .services-container .service .service-content .content .pricing-methods {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    background-color: #1878F1;
    color: #fff;
    /* padding-left: 5px; */
    /* padding-right: 5px;   */
    border-radius: 5px;
    width: 100%;
}

.profile-container .profile-contents .services-container .service .service-content .content .pricing-methods .method {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1878F1;
    border-right: 1px solid #fff;
    width: 34%;
    /* padding-left: 5px; */
    cursor: pointer;
}

.profile-container .profile-contents .services-container .service .service-content .content .pricing-methods .method:hover {
    background-color: #83b6f8;
}

.profile-container .profile-contents .services-container .service .service-content .content .pricing-methods .method.content-selected {
    background-color: #83b6f8;
}

.profile-container .profile-contents .services-container .service .service-content .content .pricing-methods .method:last-child {
    border-right: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.profile-container .profile-contents .services-container .service .service-content .content .pricing-methods .method:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.profile-container .profile-contents .services-container .service .service-content .content .pricing-methods .method .method-header {
    /* color: gray; */
}

.profile-container .profile-contents .services-container .service .service-content .content.content-provider {
    /* margin-bottom: 2vh; */
}

.profile-container .profile-contents .services-container .service .service-content .content .content-providers {
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    align-items: center;
    width: 100%; 
    background-color: #1878F1;
    height: 5vh;
    color: #fff;
    border-radius: 5px;
    /* padding-left: 5px; */

}

.profile-container .profile-contents .services-container .service .service-content .content .content-providers .provider {
    width: 50%;
    text-align: center;
    height: 5vh;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.profile-container .profile-contents .services-container .service .service-content .content .content-providers .provider:first-child {
    border-right: 1px solid #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.profile-container .profile-contents .services-container .service .service-content .content .content-providers .provider:hover {
    background-color: #83b6f8;
}

.profile-container .profile-contents .services-container .service .service-content .content .content-providers .provider.content-selected {
    background-color: #83b6f8;
}

.profile-container .profile-contents .services-container .service .service-content .content.action .service-button {
    background-color: #1878F1;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    height: 5vh;
}

.profile-container .profile-contents .services-container .service .service-content .content.action .service-button:hover {
    background-color: #83b6f8;
}

.profile-container .profile-contents .influencer-analytics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden; 
    overflow-y: auto; 
    scrollbar-width: none;
    height: 88vh;
    padding-left: 2%;
    padding-right: 2%;
}

.profile-container .profile-contents .influencer-analytics .engagement-metrics,
.profile-container .profile-contents .influencer-analytics .audience-demographics,
.profile-container .profile-contents .influencer-analytics .summary-container {
    width: 100%;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
}

.profile-container .profile-contents .influencer-analytics .engagement-metrics .header,
.profile-container .profile-contents .influencer-analytics .audience-demographics .header,
.profile-container .profile-contents .influencer-analytics .summary-container .header {
    height: 5vh;
    border-bottom: 2px solid #f29219;
    padding-left: 0.5%;
    color: #000;
    font-size: 1.1em;
    border-right: none;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: flex-start;
    /* background-color: red; */
    font-weight: bold;
    font-size: 1.3em;
    font-family: "Nunito";

}

.profile-container .profile-contents .influencer-analytics .summary-container .content {
    height: 85vh;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-top {
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    height: 20vh;
    margin-top: 1vh;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-top .profile-picture {
    width: 20%; 
    display: flex;
    flex-direction: row; 
    justify-content: center; 
    align-items: center;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-top .profile-picture img {
    width: 130px;
    height: 130px;
    border-radius: 50%;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-top .profile-information {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: flex-start;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-top .profile-information .profile-name {
    font-size: 1.5em;
    font-weight: bold;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-top .profile-information .profile-account {
    
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-top .profile-information .profile-bio {
    
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-middle {
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    gap: 2%;
    margin-top: 5vh;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-middle .summary {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    
    width: 32%; 
    height: 25vh;
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: #f29219;
    background-color: #1878F1;
    color: #fff;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-middle .top-align {
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    font-size: 2.3em;
    font-weight: bold;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-middle .top-align .icon {
    margin-right: 20%;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-middle .top-align .icon i {
    font-size: 1.5em;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-middle .bottom-align {
    font-size: 1.2em;
    color: gray;
    color: #fff;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2%;
    row-gap: 1vh;
    flex-wrap: wrap;
    margin-top: 2vh;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-bottom .summary {
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    font-size: 2.3em;
    width: 32%;
    height: 19vh;
    height: 25vh;
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: #f29219;
    background-color: #83b6f8;
    background-color: #1878F1;
    color: #fff;

}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-bottom .summary .top-align {
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    font-weight: bold;
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-bottom .summary .top-align .icon {
    margin-right: 20%; 
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-bottom .summary .top-align .icon i {
    font-size: 1.5em;
    /* color: #f29219; */
}

.profile-container .profile-contents .influencer-analytics .summary-container .content .summary-bottom .summary .bottom-align {
    font-size: 20px;
    color: gray;
    color: #fff;
}

.profile-container .profile-contents .influencer-analytics .engagement-metrics .content,
.profile-container .profile-contents .influencer-analytics .audience-demographics .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    /* padding-bottom: 1vh; */
}

.profile-container .profile-contents .influencer-analytics .engagement-metrics .content .navigation,
.profile-container .profile-contents .influencer-analytics .audience-demographics .content .navigation {
    width: 100%; 
    height: 5vh;
    /* background-color: red; */
    background-color: #fff;
    padding-left: 10px;
}   

.profile-container .profile-contents .influencer-analytics .engagement-metrics .content .chart-container {
    width: 100%; 
    display: flex;
    flex-direction: row; 
    justify-content: flex-start; 
    gap: 1.1%;
    row-gap: 1vh;
    flex-wrap: wrap;
    /* padding-left: 1%;
    padding-right: 1%; */
}

.profile-container .profile-contents .influencer-analytics .engagement-metrics .content .chart-container .chart {
    width: 32.6%; 
    height: 25vh;
    /* border: 1px solid lightgray;
    border: 1px solid #1878F1;
    border: 1px solid #f29219; */
    border-radius: 5px;    
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.profile-container .profile-contents .influencer-analytics .engagement-metrics .content .chart-container .chart .chart-title {
    width: 100%; 
    height: 3vh;
    border-bottom: 1px solid #1878F1;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: flex-start;

}


.influencer-analytics {
    scroll-snap-type: y mandatory; /* Enable vertical snapping */
    overflow-y: scroll; /* Allow vertical scrolling */
    height: 100vh; /* Set height to the full viewport */
}


.profile-container .profile-contents .influencer-analytics .audience-demographics {
    height: 88vh;
}

.profile-container .profile-contents .influencer-analytics .engagement-metrics .content .navigation,
.profile-container .profile-contents .influencer-analytics .audience-demographics .content .navigation {
    display: flex;
    flex-direction: row; 
    justify-content:center;
    align-items: center;
    gap: 10px;
    width: 30%;
    background-color: #83b6f8;
    margin-top: 1vh;
    border-radius: 20px;
    border-radius: 5px;
    font-weight: 500;
    height: 5vh;
    margin-bottom: 1vh;
    cursor: pointer;
    min-width: 400px;
}

.profile-container .profile-contents .influencer-analytics .engagement-metrics .content .navigation .nav-element,
.profile-container .profile-contents .influencer-analytics .audience-demographics .content .navigation .nav-element {
    width: 40%;
    height: 4vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border-radius: 5px;
    color: #fff;
}

.profile-container .profile-contents .influencer-analytics .engagement-metrics .content .navigation .nav-element.selected,
.profile-container .profile-contents .influencer-analytics .audience-demographics .content .navigation .nav-element.selected {
    color: #1878F1;
    background-color: #fff;
}


.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container {
    width: 100%; 
    
}

.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.location {
    width: 100%; 
    height: 70vh;
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
}

.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.location .country-chart,
.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.location .city-chart {
    width: 50%;
    height: 70vh;
    /* overflow-y: auto; */
}


.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.location .chart-header,
.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.gender-age .chart-header {
    font-weight: bold; 
    font-size: 1.2em;
    padding-left: 10px;
    /* background-color: red; */
}

.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.gender-age .chart-header  {
    margin-bottom: 5vh;
}

.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.location .country-chart .p-chart,
.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.location .city-chart .p-chart {
    width: 90%;
    height: 70vh;
    overflow-y: auto;
}

.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.location .country-chart .p-chart canvas {
    max-height: 100%;
    display: block;
}

.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.gender-age {
    width: 100%; 
    height: 50vh;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
}

.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.gender-age .gender-chart, 
.profile-container .profile-contents .influencer-analytics .audience-demographics .content .chart-container .chart.gender-age .age-chart {
    width: 45%; 
    height: 50vh;
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
}

.profile-container .profile-contents .services-container .add-campaign-overlay {
    position: absolute; 
    top: 15vh;
    left: 35%;
    width: 30%; 
    min-width: 400px;
    height: 60vh;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #1878F1;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .add-campaign-overlay-header {
    width: 100%; 
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center; 
    font-size: 1.1em;
    font-weight: bold;
    border-bottom: 1px solid lightgray;
    height: 5vh;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaigns {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%; 
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaigns .campaign-search input {
    width: 100%; 
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;    
    outline-color: #83b6f8;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaigns .campaign-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 1vh;

    overflow-x: hidden;
    overflow-y: auto;   
    height: 40vh;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaigns .campaign-content .campaign {
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    gap: 10px;
    background-color: #83b6f8;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaigns .campaign-content .p-datatable .p-datatable-table .p-datatable-thead {
    height: 5vh;    
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;   
    color: #000;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaigns .campaign-content .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead th:first-child {
    border-top-left-radius: 5px;
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;    
    align-items: center;
    height: 5vh;
    width: 100%;
    
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaigns .campaign-content .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead th:last-child {
    border-top-right-radius: 5px;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaigns .campaign-content .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody .p-checkbox-box {
    background-color: #83b6f8;
    background-color: #1878F1;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaign-selection-action { 
    width: 100%; 
    height: 5vh;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaign-selection-action button {
    width: 48%; 
    height: 5vh;
    background-color: #1878F1;
    color: #fff;
    border-radius: 5px;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaign-selection-action .select-campaign-cancel-button {
    background-color: lightcoral;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaign-selection-action .select-campaign-cancel-button:hover {
    opacity: 0.9;
}

.profile-container .profile-contents .services-container .add-campaign-overlay .campaign-selection-action .select-campaign-add-button:hover {
    background-color: #83b6f8;
}

