.earnings-container {
    width: 100%;
    height: 93vh;
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    scrollbar-width: none;
    padding-top: 2vh;
}   

.earnings-container .earnings-amount { 
    width:30%;
    /* height: 30vh; */
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.earnings-container .earnings-amount .amount {
    width:  85%;
    min-width: 100px;
    height: 30vh;
    background-color: #1878F1;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.earnings-container .earnings-amount .amount .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
}

.earnings-container .earnings-amount .amount .amount-value {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
}

.earnings-container .earnings-amount .amount .action {
    text-align: center;
    color: #fff;
    text-decoration: underline;
    font-size: 1.2rem;
}

.earnings-container .earnings-amount .amount .action span {
    cursor: pointer;
}

.earnings-container .earnings-amount .amount .action span:hover {
    opacity: 0.8;
}

.earnings-container .earnings-amount .paypal {
    width: 85%;
    height: 30vh;
    margin-top: 5vh;
     
}

.earnings-container .earnings-amount .paypal .amount-input {
    width: 100%;
    
}

.earnings-container .earnings-amount .paypal .amount-input input {
    border: 1px solid #1878F1;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 5px;
    border-radius: 5px;

    width: 100%;
    margin-bottom: 1vh;
    outline-color: #1878F1;
    outline-width: 1px;
}

.earnings-container .earnings-amount .paypal .amount-input input:focus {
    outline-color: #1878F1;
}

.earnings-history {
    width: 70%;
}

.earnings-history h2 {
    font-weight: bold;
    font-size: 1.3rem;
    padding-left: 1%;
    margin-bottom: 1vh;
}

.earnings-history .history {
    width: 100%;
    padding-right: 5%;
}



.earnings-history .history .row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    margin-bottom: 0.5vh;
     
}

.earnings-history .history .row.title-row {
    font-weight: bold;
}

.earnings-history .history .row:not(.title-row) {
    background-color: #c8defc;   
}

.earnings-history .history .row .column:first-child {
    padding-left: 1%;
}

.earnings-history .history .row .column:last-child {
    padding-right: 1%;
}

.earnings-history .history .row .column {
    width: 25%;
}

.earnings-history .history .row .column.transaction-type p{

}