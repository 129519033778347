@tailwind base;
@tailwind components;
@tailwind utilities;



.login-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.login-box-container {
    background-color: #1878f1;
    width: 50%;
    height: 80vh;
    border-radius: 10px;
}

.login-box-container .login-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Nunito";
    color: white;
    font-size: 1.5em;
    font-weight: bold;
}

.login-box-container form label {
    color: white;
    font-family: "Nunito";
    font-size: 1.1em;
    font-weight: bold;
}

.login-box-container form button {
    margin-top: 5vh;
    background-color: #f29219;
    color: #fff;
    font-weight: bold;
    font-size: 1.1em;
    padding-top: 10px;
    padding-bottom: 10px;
}

.login-box-container form button:hover {
    opacity: 0.8;
}

.login-email-input,
.login-password-input
{
    border-color: #1878f1;
    outline-color: #1878f1;
}

.login-forgot, .login-14 {
    color: #1878f1;     
}

.login-forgot:hover, 
.login-14:hover
{
    color: #83b6f8;
}

.login-submit {
    background-color: #1878f1;
}

/* .login-submit:hover {
    background-color: #83b6f8;
} */