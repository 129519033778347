.campaign-management-container {
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 3vh;
    font-family: "Nunito";
}

.campaign-management-container .header {
    color: gray;
    height: 3vh;
}

.campaign-management-container .header nav {
    height: 3vh;
}

.campaign-management-container .header button {
    background-color: #1878F1;
    color: #fff;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.campaign-management-container .header button:hover {
    opacity: 0.8;
}

.campaign-management-container .header button p {
    opacity: 1;
}



.campaign-management-container .header i {
    padding-right: 10px;
    /* transform: translateY(3px); */
}




.campaign-management-container .campaigns {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.campaign-management-container .campaigns .campaign {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.campaign-management-container .campaigns .campaign.title {
    font-weight: bold;
    background-color: #fff;
}

.campaign-management-container .campaigns .scroll-container {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}

.campaign-management-container .campaigns .campaign {
    background-color: #d2e4fc;
    margin-bottom: 1vh;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    border-radius: 5px;
    cursor: pointer;
}

.campaign-management-container .campaigns .campaign:not(.title):hover {
    background-color: #83b6f8;
}

.campaign-management-container .campaigns .campaign .column:first-child {
    padding-left: 1%;
}

.campaign-management-container .campaigns .campaign .column:last-child {
    padding-right: 1%;
}

.campaign-management-container .campaigns .campaign .column.contract,
.campaign-management-container .campaigns .campaign .column.file {
    text-decoration: underline;
    color: #1878f1;
}

.campaign-management-container .campaigns .campaign .column {
    width: 12.5%;
}

.campaign-management-container .campaigns .campaign .column.published  p{
    background-color: lightgreen;
    width: 50%;
    border-radius: 5px;
    text-align: center;
    /* padding-left: 5px;
    padding-right: 5px; */
}

.campaign-management-container .campaigns .campaign .column.waiting  p {
    background-color: lightyellow;
    width: 50%;
    border-radius: 5px;
    text-align: center;
    /* padding-left: 5px;
    padding-right: 5px; */
}

.campaign-management-container .campaigns .campaign .column.failed  p {
    background-color: rgb(248, 87, 87);
    width: 50%;
    border-radius: 5px;
    text-align: center;
    /* padding-left: 5px;
    padding-right: 5px; */
}

.campaign-management-container .campaigns .campaign .column.analytics-action {
    text-decoration: underline;
    color: #1878f1;
    font-weight: bold;
}

/* Analytics styling */
.campaign-analytics-containers .analytics {
    width: 100%;
    height: 88vh;
    padding-left: 2%;
    padding-right: 2%;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
}
.campaign-analytics-containers .analytics .analytics-engagement,
.campaign-analytics-containers .analytics .analytics-demographics {    
    scroll-snap-align: start;    

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: 88vh;
}

.campaign-analytics-containers .analytics .analytics-engagement .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.campaign-analytics-containers .analytics .analytics-engagement .title span {
    text-decoration: underline;
    color: #1878f1;
    font-size: 0.9em;
    cursor: pointer;
}

.campaign-analytics-containers .analytics .analytics-engagement .title,
.campaign-analytics-containers .analytics .analytics-demographics .title {
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    margin-top: 1vh;
    font-size: 1.2em;
    border-bottom: 2px solid #f29219;
}

.campaign-analytics-containers .analytics .analytics-engagement .sub-header,
.campaign-analytics-containers .analytics .analytics-demographics .sub-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

}

.campaign-analytics-containers .analytics .analytics-engagement .sub-header .filter,
.campaign-analytics-containers .analytics .analytics-demographics .sub-header .filter {
    margin-top: 1vh;
}


.campaign-analytics-containers .analytics .analytics-engagement .navigation,
.campaign-analytics-containers .analytics .analytics-demographics .navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    margin-bottom: 3vh;
    width: 80%;
}

.campaign-analytics-containers .analytics .analytics-engagement .navigation .nav,
.campaign-analytics-containers .analytics .analytics-demographics .navigation .nav {
    width: 13%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 10px;
    text-align: center;
    margin-top: 1vh;
    margin-right: 1%;
    color: #1878F1;
    border: 2px solid #1878F1;   
    cursor: pointer; 
}

.campaign-analytics-containers .analytics .analytics-engagement .navigation .nav:hover,
.campaign-analytics-containers .analytics .analytics-demographics .navigation .nav:hover {
    background-color: #1878F1;
    /* background-color: #83b6f8; */
    color: #fff;
}

.campaign-analytics-containers .analytics .analytics-demographics .navigation .nav {
    width: 14%;
}

.campaign-analytics-containers .analytics .navigation .nav.selected {
    background-color: #1878F1;
    color: #fff;
}
.campaign-analytics-containers .analytics .analytics-engagement .content {
    width: 100%;
    height: 80vh;
    
    display: flex;
    flex-direction: row; 
    justify-content: space-between; 
    flex-wrap: wrap;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .graph-container.hidden {
    visibility: hidden;    
}

.campaign-analytics-containers .analytics .analytics-engagement .content .graph-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    border: 0.5px solid lightgray;    
    width: 32.5%;
    height: 23vh;
    border-radius: 15px;

    padding-left: 1%;
    padding-right: 1%;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .graph-container .title {
    border: none;
    font-size: 1em;
    height: 3.5vh;
    line-height: 3vh;
    
    border-bottom: 1px solid #1878F1;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .graph-container .graph {
    width: 100%;
    height: 19vh;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .website-clicks .graph {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .website-clicks .graph .content {
    width: 70%;
    height: 19vh;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .website-clicks .graph .changes {
    width: 30%;
    height: 19vh;
    padding-top: 1vh;
    padding-bottom: 1vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .website-clicks .graph .changes .quantity {
    font-weight: bold;
    font-size: 1.2em;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .website-clicks .graph .changes .increase {
    color: #22da22;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .website-clicks .graph .changes .text {
    color: gray;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .sentiment .graph {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .sentiment .graph .content {
    width: 80%;
    height: 17vh;
    padding-top: 1vh;        
}

.campaign-analytics-containers .analytics .analytics-engagement .content .sentiment .graph .value {
    width: 20%;
    height: 17vh;
    line-height: 17vh;
    font-size: 1.5em;
    font-weight: bold;
}

.campaign-analytics-containers .analytics .analytics-engagement .content .follow-unfollow .graph {
    width: 80%;
    height: 17vh;
    padding-top: 1vh;        
}

.campaign-analytics-containers .analytics .analytics-demographics .content { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.campaign-analytics-containers .analytics .analytics-demographics .content .follower-demographics,
.campaign-analytics-containers .analytics .analytics-demographics .content .engaged-demographics, 
.campaign-analytics-containers .analytics .analytics-demographics .content .reached-demographics { 
    width: 32%;
    height: 70vh;
    border-radius: 10px;
    border: 1px solid lightgray;    

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.campaign-analytics-containers .analytics .analytics-demographics .content .follower-demographics .title,
.campaign-analytics-containers .analytics .analytics-demographics .content .engaged-demographics .title,
.campaign-analytics-containers .analytics .analytics-demographics .content .reached-demographics .title {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    margin-top: 0;
    margin-bottom: 1vh;
    border-bottom: 1px solid lightgray;
    padding-left: 5%;
    padding-right: 5%;

    font-size: 0.9em;
}

.campaign-analytics-containers .analytics .analytics-demographics .content .follower-demographics .content,
.campaign-analytics-containers .analytics .analytics-demographics .content .engaged-demographics .content,
.campaign-analytics-containers .analytics .analytics-demographics .content .reached-demographics .content {
    width: 100%;
    height: 64vh;
    padding-left: 5%;
    padding-right: 5%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    border: none;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.campaign-analytics-containers .analytics .analytics-demographics .content .follower-demographics .content .row,
.campaign-analytics-containers .analytics .analytics-demographics .content .engaged-demographics .content .row,
.campaign-analytics-containers .analytics .analytics-demographics .content .reached-demographics .content .row {
    width: 100%;
    height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.campaign-analytics-containers .analytics .analytics-demographics .content .follower-demographics .content .row:nth-child(1),
.campaign-analytics-containers .analytics .analytics-demographics .content .engaged-demographics .content .row:nth-child(1),
.campaign-analytics-containers .analytics .analytics-demographics .content .reached-demographics .content .row:nth-child(1) {
    font-weight: bold;
}

.campaign-analytics-containers .analytics .analytics-demographics .content .follower-demographics .content .row .column:nth-child(1),
.campaign-analytics-containers .analytics .analytics-demographics .content .engaged-demographics .content .row .column:nth-child(1),
.campaign-analytics-containers .analytics .analytics-demographics .content .reached-demographics .content .row .column:nth-child(1) {
    width: 80%;
    line-height: 5vh;
}

.campaign-analytics-containers .analytics .analytics-demographics .content .follower-demographics .content .row .column:nth-child(2),
.campaign-analytics-containers .analytics .analytics-demographics .content .engaged-demographics .content .row .column:nth-child(2),
.campaign-analytics-containers .analytics .analytics-demographics .content .reached-demographics .content .row .column:nth-child(2) {
    width: 20%;
    line-height: 5vh;
    text-align: center;
}

.campaign-analytics-containers .analytics .analytics-demographics .content .follower-demographics .content .row:nth-child(1) .column:nth-child(2),
.campaign-analytics-containers .analytics .analytics-demographics .content .engaged-demographics .content .row:nth-child(1) .column:nth-child(2),
.campaign-analytics-containers .analytics .analytics-demographics .content .reached-demographics .content .row:nth-child(1) .column:nth-child(2) {
    background-color: #1878F1;
    color: #fff;
    border-radius: 15px;
}

.campaign-analytics-containers .analytics .analytics-demographics .age .follower-demographics .content {
    width: 100%;
} 



.p-column-filter-overlay {
    background-color: #1878F1;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
}

.p-dropdown-panel {
    background-color: #fff;
    color: #000;
}

.p-column-filter-overlay div {
    margin-bottom: 1vh;
}

.p-column-filter-overlay input {
    padding: 5px;
    border-radius: 5px;
    color: #000;
    outline-color: #1878F1;
}

table {
    margin-top: 1vh;
    font-family: "Nunito";
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead {
    background: transparent;
    height: 7vh;
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead th {
    background: #83b6f8;
    /* background: #1878F1;    
    color: #fff; */
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead th:first-child {
    border-top-left-radius: 10px;
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead th:last-child {
    border-top-right-radius: 10px;
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr {
    height: 5vh;
}

thead .p-column-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Nunito";
    padding-left: 10px;
}

thead .p-column-filter button {
    margin-right: 10px;
    /* color: #1878F1; */
    font-weight: bold;
    background-color: rgb(235, 231, 231);
    background-color: #fff;
    /* color: #83b6f8; */
    padding: 5px;
    border-radius: 50%;
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr td {
    padding-left: 10px;
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr:hover {
    background-color: #83b6f8;
    cursor: pointer;
    color: #fff;
}

.campaign-management-container .new-campaign-overlay {
    position: absolute;
    top: 30vh; 
    left: 35%; 
    width: 30%; 
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    border: 2px solid #1878F1;
    background-color: #fff;
    color: black;  
    z-index: 2;
    border-radius: 5px; 
    padding-left: 1%; 
    padding-right: 1%;  
}


.campaign-management-container .new-campaign-overlay .new-campaign-header {
    font-size: 1.1em;
    font-weight: bold;
    font-family: "Nunito";
    height: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.campaign-management-container .new-campaign-overlay .new-campaign-content {
    height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.campaign-management-container .new-campaign-overlay .new-campaign-content label {
    font-size: 1em;
    font-weight: bold;
    margin-right: 5%;
}

.campaign-management-container .new-campaign-overlay .new-campaign-content input {
    border: 2px solid #1878F1;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    height: 5vh;
    border-radius: 5px;
}

.campaign-management-container .new-campaign-overlay .new-campaign-action {
    width: 100%; 
    height: 5vh;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.campaign-management-container .new-campaign-overlay .new-campaign-action button {
    width: 30%; 
    height: 4vh;
    border-radius: 5px;
    cursor: pointer;
}

.campaign-management-container .new-campaign-overlay .new-campaign-action button:hover {
    opacity: 0.9;
}

.campaign-management-container .new-campaign-overlay .new-campaign-action button:first-child {
    background-color: salmon;
}

.campaign-management-container .new-campaign-overlay .new-campaign-action button:nth-child(2) {
    background-color: lightgreen;
}

.campaign-management-container .new-campaign-overlay.not-visible {
    display: none;
}