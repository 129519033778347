.core-container {
    display: grid;
    grid-template-areas: 
    'aside header'
    'aside main'
    ;
    grid-template-columns: 1fr 6fr;
    /* grid-template-columns: auto, 1fr; */
    /* grid-template-columns: auto 1fr; */
    /* grid-template-columns: 250px 1fr; */

    grid-template-rows: 7vh 93vh;
    /* transition: grid-template-columns 0.5s; */
}

.core-container header {
    grid-area: header;
    border-bottom: 1px solid #373737;
    padding-left: 2%;
    padding-right: 2%;
    line-height: 7vh;

    font-weight: bold;
    font-size: 1.5em;
}

.core-container aside {
    grid-area: aside;
    background-color: #1878F1;
}

.core-container aside .logo {
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding-right: 15%; */
}

.core-container aside .logo img {
    width: 150px;
    height: 150px;
}

.core-container aside nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.core-container aside nav .nav-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: #fff;

    width: 80%;
    height: 10vh;
    line-height: 10vh;
    border-radius: 20px;
    cursor: pointer;
}
.core-container aside nav .nav-btn .icon {
    width: 30%;
    line-height: 11vh;
    text-align: center;
}

.core-container aside nav .nav-btn .icon span {
    font-size: 1.5em;
}

.core-container aside nav .nav-btn .icon text {

}

.core-container aside nav .nav-btn:hover {
    background-color: #83b6f8;
}

.selected {
    background-color: #83b6f8;
}

.core-container main {
    grid-area: main;
}

.sidebar-toggle-left {
    position: absolute;
    /* top: 10vh; */
    /* top: 7.5vh; */
    top: 2vh;
    left: 13.3%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f29219;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.sidebar-toggle-right {
    position: absolute;
    /* top: 10vh; */
    /* top: 7.5vh; */
    top: 2vh;   
    left: -0.5%;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f29219;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.sidebar-toggle-left .left-arrow {
    width: 10px;
    height: 10px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    margin-left: 3px;
    transform: rotate(-45deg);
}

.sidebar-toggle-right .right-arrow {
    width: 10px;
    height: 10px;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    /* margin-left: 3px; */
    transform: rotate(45deg);
}

.core-container.collapsed {
    grid-template-columns: 0fr 1fr;
}

.collapsed aside {
    width: 0%;
    display: none;
}

.collapsed main {
    width: 100%;
}

