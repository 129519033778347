.discovery-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: 93vh;

    font-family: "Nunito";

}

.discovery-container .header {
    width: 100%;
    height: 10vh;
    border-bottom: 1px solid lightgray;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1vh;
    color: gray;
}

.discovery-container .header .name-search .title, 
.discovery-container .header .location-search .title {
    margin-bottom: 0.5vh;
}

.discovery-container .header .name-search {
    width: 45%;
}

.discovery-container .header .name-search input, 
.discovery-container .header .location-search input {
    border: 2px solid #000;
    color: #000;
    width: 100%;
    border-radius: 13px;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.discovery-container .header .name-search input:focus,
.discovery-container .header .location-search input:focus {
    outline-color: #1878F1;
    outline-offset: 1px;
    border: 2px solid #1878F1;
}

.discovery-container .header .location-search {
    width: 45%;
}

.discovery-container .header .more-filters .title {
    visibility: hidden;
}

.discovery-container .header .more-filters span {
    background-color: #1878F1;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 13px;

    cursor: pointer; 
    color: #fff;
}

.discovery-container .header .more-filters span:hover {
    background-color: #83b6f8;
}

.discovery-container .chat-search-container {
    width: 100%;
    height: 10vh;
    border-bottom: 1px solid lightgray;

    /* padding-left: 2%; */
    padding-left: 5%;
    padding-right: 2%;
    padding-bottom: 1vh;
}

.discovery-container .chat-search-container .title {
    color: gray;
    margin-bottom: 0.5vh;
}

.discovery-container .chat-search-container .chat-search {
    width: 100%;
}

.discovery-container .chat-search-container .chat-search input {
    border: 2px solid #000;
    color: #000;
    width: 93%;
    border-radius: 13px;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.discovery-container .chat-search-container .chat-search input:focus {
    outline-color: #1878F1;
    outline-offset: 1px;
    border: 2px solid #1878F1;
}

.discovery-container .body {
    width: 100%;
    height: 83vh;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2.5vh; 

    overflow-x: hidden;
    overflow-y: auto;
}

.discovery-container .body .result-card {
    /* width: 32.65%; */
    width: 32.66%;
    /* min-width: 400px; */
    /* width: 23%; */
    height: 25vh;
    /* margin-right: 2%; */
    border: 1px solid gray;
    border-radius: 20px;
    margin-bottom: 2vh;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* flex-grow: 1; */
}

.discovery-container .body .result-card:nth-of-type(n) {
    margin-right: 1%;
}

.discovery-container .body .result-card:nth-of-type(3n) {
    margin-right: 0%;
} 

/* .discovery-container .body .result-card:hover {
    border: 1px solid #1878F1;
} */

.discovery-container .body .result-card .profile-image {
    width:32%;
    height: 25vh;
    padding-left: 1%;
    padding-right: 1%;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.discovery-container .body .result-card .profile-image img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.discovery-container .body .result-card .profile-info {
    width: 80%;
    /* height: 20vh; */
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* padding-left: 1%; */
}

.discovery-container .body .result-card .profile-info .username .content {
    font-size: 1.2em;
    font-weight: bold;
}

.discovery-container .body .result-card .profile-info .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* justify-content: space-evenly; */
    margin-bottom: 2.5vh;
}

.discovery-container .body .result-card .profile-info .row .main-audience-location {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 3%
}


.discovery-container .body .result-card .profile-info .row .engagement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.discovery-container .body .result-card .profile-info .row .followers {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* margin-right: 10%; */
    margin-right: 3%
}

.discovery-container .body .result-card .profile-info .row .followers .icon, 
.discovery-container .body .result-card .profile-info .row .main-audience-location .icon,
.discovery-container .body .result-card .profile-info .row .engagement .icon {
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    padding-right: 10%;
    color: #1878F1;
}

.discovery-container .body .result-card .profile-info > div{
    width: 100%;
    height: 5vh;
    /* margin-bottom: 0.8vh; */
}

.discovery-container .body .result-card .profile-info .username .content {
    color: #1878F1;
}

.discovery-container .body .result-card .profile-info .price .content,
.discovery-container .body .result-card .profile-info .followers .content,
.discovery-container .body .result-card .profile-info .main-audience-location .content,
.discovery-container .body .result-card .profile-info .engagement .content {
    color: #f2994a;
}

.discovery-container .body .result-card .profile-info .price .content {
    font-weight: bold;

}

.discovery-container .body .result-card .profile-info > div .title p {
    color: gray;
    /* font-size: 0.8em; */
    font-size: 0.65em;
    font-size: 0.8em;
}

.discovery-container .body .result-card .profile-info .price .content {
    font-size: 1.5em;
}


/* .main-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.main-container .sidebar {
    width: 18%;
    height: 93vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
} */

.main-container {
    width: 100%;
    height: 93vh;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.main-container .sidebar {
    width: 25%;
    min-width: 305px;
    height: 93vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 1px solid lightgray;
    background-color: #edf5ff;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.main-container .sidebar .title {
    width: 100%;
    height: 7vh;
    line-height: 7vh;
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
    
    margin-bottom: 1vh;
    
}

.main-container .sidebar .filters {
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: center;

    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}

.main-container .sidebar .filters .filter {
    width: 90%;
    /* height: 30vh; */
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 15px;
    margin-bottom: 2vh;
}

.main-container .sidebar .filters .filter .filter-title {
    margin-bottom: 1vh;
    /* font-weight: bold; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.main-container .sidebar .filters .filter .filter-title span {
    color: #1878F1;
    margin-right: 5%;
}

.main-container .sidebar .filters .filter .filter-content input {
    width: 100%;
    border-radius: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5%;
    padding-right: 2%;
    border: 1px solid lightgray;
}

.main-container .sidebar .filters .filter .filter-content input[type="text"]:focus {
    outline-color: #1878F1;
    outline-offset: 1px;
    border: 1px solid #1878F1;
}

.main-container .sidebar .filters .filter .filter-content input[type="range"] {
    height: 10px;   
}

.main-container .sidebar .filters .filter .filter-content input[type="range"],
.main-container .sidebar .filters .filter .filter-content input[type="range"]::-webkit-slider-runnable-track,
.main-container .sidebar .filters .filter .filter-content input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

.main-container .sidebar .filters .filter .filter-content input[type="range"]::-webkit-slider-runnable-track {

}

.main-container .sidebar .filters .filter .filter-content input[type="range"]::-webkit-slider-thumb {
    position: relative;
    background-color: #1878F1;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
}

.main-container .sidebar .filters .filter .filter-content input[type="range"]::-webkit-slider-thumb::before {
    position: absolute;
    content: '';
    height: 10px; 
    width: 500px; 
    left: -502px; 
    top: 8px; 
    background: #777;
}

.main-container .sidebar .filters .followers .filter-content p {
    width: 100%;
    text-align: center;
    font-weight: bold;
    margin-top: 0.5vh;
    font-size: 1.1em;
}

.main-container .sidebar .filters .location .filter-content .menu-flags-select button {
    background-color: #fff; 
    border-radius: 15px;
    height: 5vh;
}

.main-container .sidebar .filters .location .filter-content .selected-locations .container,
.main-container .sidebar .filters .filter .filter-content .selected-niches .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    flex-wrap:wrap;
}

.main-container .sidebar .filters .location .filter-content .selected-locations .container .location,
.main-container .sidebar .filters .filter .filter-content .selected-niches .container .niche-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 27%;
    margin-left: 5%;
    margin-bottom: 1vh;
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    flex-wrap: wrap;
}

.main-container .sidebar .filters .filter .filter-content .selected-niches .container .niche-item {
    width: 35%;
}

.main-container .sidebar .filters .location .filter-content .selected-locations .container .location span, 
.main-container .sidebar .filters .niches .filter-content .selected-niches .container .niche-item span {
    font-size: 0.8em;
    color: #1878F1;
    cursor: pointer;
}

.main-container .sidebar .filters .location .filter-content .selected-locations .container .location .flag {

}

/* .main-container .sidebar .filters .location .filter-content .selected-locations .location,
.main-container .sidebar .filters .filter .filter-content .selected-niches .container .niche-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */

.main-container .discovery-container .results-table {
    width: 100%;
    height: 83vh;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
}

.main-container .discovery-container .results-table .title-row {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: flex-start;
    margin-top: 2vh;
    /* margin-bottom: 2vh; */
    margin-bottom: 1vh;
    padding-bottom: 1vh;
    border-bottom: 1px solid lightgray;
}

.main-container .discovery-container .results-table .title-row .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    cursor: pointer;
    
    /* width: 18%; */
    /* margin-right: 4%; */
    
}

.main-container .discovery-container .results-table .profile {
    width: 8.5%;
    margin-right: 4%;
}

.main-container .discovery-container .results-table .username {
    width: 12%;
    margin-right: 4%;
}

.main-container .discovery-container .results-table .followers {
    width: 8.5%;
    margin-right: 4%;
}

.main-container .discovery-container .results-table .location {
    width: 16.5%;
    margin-right: 4%;
}

.main-container .discovery-container .results-table .engagement {
    width: 12%;
    margin-right: 4%;
}

.main-container .discovery-container .results-table .niche {
    width: 8.5%;
    margin-right: 4%;
}

.main-container .discovery-container .results-table .price {
    
}

.main-container .discovery-container .results-table .title .icon {
    /* padding-right: 10px; */
    color: #1878F1;
    color: #83b6f8;
    margin-right: 5%;
}

.main-container .discovery-container .results-table .title-row .title .text {
    /* font-family: sans-serif; */
}

.main-container .discovery-container .results {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.main-container .discovery-container .results .result {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0.3vh;
    padding-top: 1vh;
    padding-bottom: 1.4vh;
    /* border-bottom: 1px solid rgb(233, 230, 230); */
    /* font-weight: bold; */

    cursor: pointer;
}

.main-container .discovery-container .results .result:hover {
    background-color: #83b6f8 !important;
    border-radius: 10px;
}

.main-container .discovery-container .results .result:nth-of-type(even) {
    background-color: #c8defc;
    border-radius: 10px;
}

.main-container .discovery-container .results .result > div {
    padding-left: 2%;
    height: 5vh;
    line-height: 5vh;
    
}

.main-container .discovery-container .results .result  .username {
    white-space: nowrap;         /* Prevents the text from wrapping to the next line */
    overflow: hidden;            /* Hides the overflowed text */
    text-overflow: ellipsis;  
}

.main-container .discovery-container .results .result .profile img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.display-none-property {
    display: none;
}

.p-column-filter-overlay {
    background-color: #1878F1;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
}

.p-dropdown-panel {
    background-color: #fff;
    color: #000;
}

.p-column-filter-overlay div {
    margin-bottom: 1vh;
}

.p-column-filter-overlay input {
    padding: 5px;
    border-radius: 5px;
    color: #000;
    outline-color: #1878F1;
}

table {
    margin-top: 1vh;
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead {
    background: transparent;
    height: 7vh;
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead th {
    background: #83b6f8;
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead th:first-child {
    border-top-left-radius: 10px;
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead th:last-child {
    border-top-right-radius: 10px;
}

.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr {
    height: 5vh;
}

thead .p-column-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Nunito";
    padding-left: 10px;
}

thead .p-column-filter button {
    margin-right: 10px;
    /* color: #1878F1; */
    font-weight: bold;
    background-color: rgb(235, 231, 231);
    padding: 5px;
    border-radius: 50%;
}