.date-range-picker-container {
    position: relative;
    display: inline-block;
    border: 2px solid #1878F1;
    border-radius: 15px;
}

.date-range-picker-container input {
    border-radius: 15px;
    border: none;    
    outline: none;

}

.date-picker-wrapper {
    position: absolute;
    top: 40px; /* Adjust based on your input field height */
    left: -40%;
    z-index: 1000;
    background: white;
    
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
