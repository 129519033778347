.overlay-trigger {
    background-color: #1878F1;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.overlay-trigger:hover {
    opacity: 0.9;
}



.empty-button {
    visibility: hidden;
}

.new-service-form {
    z-index: 1000;
    position: absolute; 
    top:  15vh;
    left: 30%;
    width: 40%;
    min-width: 500px;
    height: 65;
    /* height: 75vh; */
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid #83b6f8;
    color: #000;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.new-service-form .header {
    width: 100%; 
    height: 5vh;
    padding-left: 15%;
    padding-right: 2%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.new-service-form .header .title {
    width: 80%;
    text-align: center;
    font-weight: bold;
    color: #000;
    font-size: 1.3em;
}

.new-service-form .header .close {
    line-height: 7vh;
    cursor: pointer;
    color: #000;
}

.new-service-form .header .close:hover {
    color: #83b6f8;
}

.new-service-form .body {
    width: 100%;
    height: 60vh;  
    padding-top: 2vh;
    padding-left: 5%;
    padding-right: 5%;
    text-align: left;
}

.new-service-form .body .p-stepper-nav {
    margin-bottom: 5vh;
}

.new-service-form .body .control-buttons {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
}

.new-service-form .body .control-buttons button {
    background-color: #1878F1;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 15%;
}

.new-service-form .body .control-buttons button:hover {
    opacity: 0.9;
}

.new-service-form .body .control-buttons button:hover span {
    opacity: 0.9;
}

.new-service-form .body .service-content .pricings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2vh;
    height: 55vh;
}

.new-service-form .body .service-content .pricings .pricings-header {
    color: #000;
    font-weight: bold;
}

.new-service-form .body .service-content .pricings .content-source {
    width: 100%; 
    height: 5vh; 
    background: #eef3f9;
    background-color: #83b6f8;
    color: #1878F1;
    color: #fff;
    font-weight: bold;
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;
    border-radius: 5px;
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.new-service-form .body .service-content .pricings .content-source.business {
    margin-top: 5vh;
}

.new-service-form .body .service-content .pricings .content-source .source-header {
    width: 30%;
    border-right: 2px solid #fff;
}

.new-service-form .body .service-content .pricings .content-source .source-content {
    width: 70%;
    text-align: center;
    /* padding-left: 10px; */
}

.new-service-form .body .service-content .pricings .pricings-content > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.new-service-form .body .service-content .pricings .pricings-content {
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    margin-top: 1vh;
}

.new-service-form .body .service-content > div .pricing-header {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
    /* padding-right: 10%; */
    width: 30%;
    font-weight: bold;
}

.new-service-form .body .service-content > div input[type="number"] {
    width: 30%;
    text-align: center;
}

.new-service-form .body .service-content > div .pricing-header input[type="checkbox"] {
    width: 15px;
    height: 15px;
    cursor: pointer;
    color: #1878F1;    
}

.new-service-form .body .service-content .pricings > div {
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
}

.new-service-form .body .service-content .pricings input {
    border: 2px solid #1878F1;
    border-radius: 5px;
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
}

.new-service-form .body .service-content .pricings input:focus {
    outline-color: #1878F1;
    outline-offset: 1px;
    border: 2px solid #1878F1;
}


.new-service-form .body .service-content .pricings .service-setup-action {
    width: 100%;
}

.new-service-form .body .service-content .pricings .service-setup-action button {
    background-color: #1878F1;
    width: 100%;
    height: 5vh;
    color: #fff;
    border-radius: 5px;
    margin-top: 10vh;
    font-weight: bold;

}

.new-service-form .body .service-content .content-source {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 10vh;
}

.new-service-form .body .service-content .content-source .content-source-header {
    font-weight: bold;
    color: #000;
}

.new-service-form .body .service-content .content-source .content-source-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15.5%;
    width: 100%;
}

.new-service-form .body .service-content .content-source .content-source-content .source {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.new-service-form .body .service-content .content-source .content-source-content .source input[type="checkbox"] {
    cursor: pointer;
    width: 15px;
    height: 15px;
}

.new-service-form .body form {
    width: 67%;
    margin: auto;
}

.new-service-form .body form .form-item {
    margin-bottom: 3vh;
}

.new-service-form .body form .form-item label {
    display: inline-block;
    width: 35%;
    min-width: 100px;
}

.new-service-form .body form .form-item input {
    width: 65%;
    border: 2px solid #000;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* line-height: 2.5vh; */
    line-height: 3vh;
    /* box-sizing: border-box; */
    border: 2px solid #1878F1;
}

.new-service-form .body form .form-item select {
    width: 35%;
    border: 2px solid #000;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* line-height: 2.5vh; */
    line-height: 3vh;
    /* box-sizing: border-box; */
    outline-color: #1878F1;
    outline-offset: 1px;
    border: 2px solid #1878F1;
}

.new-service-form .body form .form-item input:focus {
    outline-color: #1878F1;
    outline-offset: 1px;
    border: 2px solid #1878F1;
}

.new-service-form .body form .form-item button {
    background-color: #1878F1;
    width: 100%;
    height: 6vh;
    border-radius: 10px;
    color: #fff;
    font-size: 1.2em;
}

.new-service-form .body form .form-item button:hover {
    background-color: #83b6f8;
}

.display-none-property {
    display: none;
}

.new-service-form .pricing-choice {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
}

.new-service-form .pricing-choice > div {
    width: 33%; 
    height: 10vh;
}

.new-service-form .pricing-choice > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 5px;
    padding-right: 5px;
}

.new-service-form .pricing-choice > div:last-child {
    padding-right: 0px;
}

.new-service-form .pricing-choice > div .pricing-title {
    width: 100%; 
    height: 5vh;   
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;

}

.new-service-form .pricing-choice > div .pricing-title input {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.new-service-form .pricing-choice > div .pricing-title span {
    display: block;
}

.new-service-form .pricing-choice > div .pricing-title input {
    display: block;
}

.new-service-form .pricing-choice > div .pricing-amount {
    width: 100%; 
    height: 5vh;
}

.new-service-form .pricing-choice > div .pricing-amount input {
    width: 100%; 
    border-radius: 5px;
    height: 5vh;
}