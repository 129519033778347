.campaign-header-container {
    width: 100%;
    height: 5vh;    
    padding: 20px;
    padding-left: 0;
    padding-right: 0;
    font-family: 'Nunito';
    font-size: 1.1em;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #000;

    -webkit-box-shadow: 2px 10px 39px -18px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 10px 39px -18px rgba(0,0,0,0.75);
    box-shadow: 2px 10px 39px -18px rgba(0,0,0,0.75);
}

.campaign-header-container .left {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

.campaign-header-container .right button {
    background-color: #1878F1;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;

    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    align-items: center;
}

.campaign-header-container .right button:hover {
    opacity: 0.8;
}

.campaign-header-container .right button:hover span {
    opacity: 1;
}

.campaign-header-container .right button i {
    color: #fff;
    padding-right: 5px;
}

.campaign-header-container .right .p-button {
    background-color: #1878F1;
    color: #fff;
    /* padding-top: 5px;
    padding-left: 5px; */
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.campaign-header-container .right .p-button:hover {
    opacity: 0.9;
}

.campaign-header-container .right .p-button:hover .p-button-label {
    opacity: 1;
}

.campaign-header-container .right .p-button svg {
    margin-right: 10px;
}

.campaign-header-container h2 {
    margin-right: 15px;
    color: gray;
    color: #000;
    font-weight: bold;
}

.campaign-header-container i {
    color: #000;
    /* color: #1878F1; */
    /* color: #83b6f8; */
    font-weight: bold;
    cursor: pointer;
    /* font-size: 0.8em; */
}

.campaign-header-container i:hover {
    color: gray;
}

