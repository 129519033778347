.research-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: 93vh;

}

.research-container .header {
    width: 100%;
    height: 10vh;
    border-bottom: 1px solid lightgray;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1vh;
    color: gray;
}

.research-container .header .name-search .title, 
.research-container .header .location-search .title {
    margin-bottom: 0.5vh;
}

.research-container .header .name-search {
    width: 45%;
}

.research-container .header .name-search input, 
.research-container .header .location-search input {
    border: 2px solid #000;
    color: #000;
    width: 100%;
    border-radius: 13px;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.research-container .header .name-search input:focus,
.research-container .header .location-search input:focus {
    outline-color: #1878F1;
    outline-offset: 1px;
    border: 2px solid #1878F1;
}

.research-container .header .location-search {
    width: 45%;
}

.research-container .header .more-filters .title {
    visibility: hidden;
}

.research-container .header .more-filters span {
    background-color: #1878F1;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 13px;

    cursor: pointer; 
    color: #fff;
}

.research-container .header .more-filters span:hover {
    background-color: #83b6f8;
}

.research-container .chat-search-container {
    width: 100%;
    height: 10vh;
    border-bottom: 1px solid lightgray;

    /* padding-left: 2%; */
    padding-left: 5%;
    padding-right: 2%;
    padding-bottom: 1vh;

    background-color: #fff;

    z-index: 1000;
}

.research-container .chat-search-container .title {
    color: gray;
    margin-bottom: 0.5vh;
}

.research-container .chat-search-container .chat-search {
    width: 100%;
    background-color: #fff;
}

.research-container .chat-search-container .chat-search input {
    border: 2px solid #000;
    color: #000;
    width: 93%;
    border-radius: 13px;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.research-container .chat-search-container .chat-search input:focus {
    outline-color: #1878F1;
    outline-offset: 1px;
    border: 2px solid #1878F1;
}

.research-container .action-container {
    /* z-index: -1000; */
    /* z-index: -1; */
    width: 100%;
    /* height: 10vh; */
    height: auto;
    max-height: 10vh;
    border-bottom: 1px solid lightgray;

    /* padding-left: 2%; */
    padding-left: 5%;
    padding-right: 2%;
    padding-bottom: 1vh;
    /* opacity: 1;
    visibility: visible;
    transition: all 0.5s ease-in-out; */
    
    filter: blur(0);
    opacity: 1;
    transition: all 1s ease-in-out;
    position: relative;
    top: -3vh;
}

.research-container .action-hide-btn {
    z-index: 1500;
    position: relative;
    width: 25px;
    height: 25px;
    /* top: -11vh; */
    top: -1vh;
    left: 50%;
    border-radius: 50%;
    background-color: #1878F1;
    /* transform: rotate(45deg); */
    cursor: pointer;
    color: #fff;
    transition: transform 0.2s ease-in-out;
}

.action-display-none-property {
    max-height: 1vh !important;
    /* display: none; */
    filter: blur(5px);
    transform: translateY(-10vh) !important;
    opacity: 0;
    visibility: hidden !important;
    /* opacity: 0;
    visibility: hidden !important; */
}

.action-hide-btn-top {
    top: -1vh !important;
}

.action-rotate {
    transform: rotate(180deg);
}

/* .research-container .action-hide-btn .action-arrow {
    width: 20px;
    height: 20px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
} */

.research-container .body {
    width: 100%;
    height: 83vh;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2.5vh; 

    overflow-x: hidden;
    overflow-y: auto;
}

.research-container .body .result-card {
    /* width: 32.65%; */
    width: 32.66%;
    /* min-width: 400px; */
    /* width: 23%; */
    height: 25vh;
    /* margin-right: 2%; */
    border: 1px solid gray;
    border-radius: 20px;
    margin-bottom: 2vh;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* flex-grow: 1; */
}

.research-container .body .result-card:nth-of-type(n) {
    margin-right: 1%;
}

.research-container .body .result-card:nth-of-type(3n) {
    margin-right: 0%;
} 

/* .research-container .body .result-card:hover {
    border: 1px solid #1878F1;
} */

.research-container .body .result-card .profile-image {
    width:32%;
    height: 25vh;
    padding-left: 1%;
    padding-right: 1%;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.research-container .body .result-card .profile-image img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.research-container .body .result-card .profile-info {
    width: 80%;
    /* height: 20vh; */
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* padding-left: 1%; */
}

.research-container .body .result-card .profile-info .username .content {
    font-size: 1.2em;
    font-weight: bold;
}

.research-container .body .result-card .profile-info .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* justify-content: space-evenly; */
    margin-bottom: 2.5vh;
}

.research-container .body .result-card .profile-info .row .main-audience-location {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 3%
}


.research-container .body .result-card .profile-info .row .engagement {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.research-container .body .result-card .profile-info .row .followers {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* margin-right: 10%; */
    margin-right: 3%
}

.research-container .body .result-card .profile-info .row .followers .icon, 
.research-container .body .result-card .profile-info .row .main-audience-location .icon,
.research-container .body .result-card .profile-info .row .engagement .icon {
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    padding-right: 10%;
    color: #1878F1;
}

.research-container .body .result-card .profile-info > div{
    width: 100%;
    height: 5vh;
    /* margin-bottom: 0.8vh; */
}

.research-container .body .result-card .profile-info .username .content {
    color: #1878F1;
}

.research-container .body .result-card .profile-info .price .content,
.research-container .body .result-card .profile-info .followers .content,
.research-container .body .result-card .profile-info .main-audience-location .content,
.research-container .body .result-card .profile-info .engagement .content {
    color: #f2994a;
}

.research-container .body .result-card .profile-info .price .content {
    font-weight: bold;

}

.research-container .body .result-card .profile-info > div .title p {
    color: gray;
    /* font-size: 0.8em; */
    font-size: 0.65em;
    font-size: 0.8em;
}

.research-container .body .result-card .profile-info .price .content {
    font-size: 1.5em;
}


/* .main-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.main-container .sidebar {
    width: 18%;
    height: 93vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
} */

.main-container {
    width: 100%;
    height: 93vh;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.main-container .sidebar {
    width: 25%;
    min-width: 305px;
    height: 93vh;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-right: 1px solid lightgray;
    background-color: #edf5ff;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.main-container .sidebar .title {
    width: 100%;
    height: 7vh;
    line-height: 7vh;
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
    
    margin-bottom: 1vh;
    
}

.main-container .sidebar .filters {
    display: flex;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: center;

    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}

.main-container .sidebar .filters .filter {
    width: 90%;
    /* height: 30vh; */
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 15px;
    margin-bottom: 2vh;
}

.main-container .sidebar .filters .filter .filter-title {
    margin-bottom: 1vh;
    /* font-weight: bold; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.main-container .sidebar .filters .filter .filter-title span {
    color: #1878F1;
    margin-right: 5%;
}

.main-container .sidebar .filters .filter .filter-content input {
    width: 100%;
    border-radius: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5%;
    padding-right: 2%;
    border: 1px solid lightgray;
}

.main-container .sidebar .filters .likes .filter-content,
.main-container .sidebar .filters .comments .filter-content {
    text-align: center;
}
.main-container .sidebar .filters .likes .filter-content input[type="text"],
.main-container .sidebar .filters .comments .filter-content input[type="text"] {
    width: 50%;
    text-align: center;
}

.main-container .sidebar .filters .filter .filter-content input[type="text"]:focus {
    outline-color: #1878F1;
    outline-offset: 1px;
    border: 1px solid #1878F1;
}

.main-container .sidebar .filters .filter .filter-content input[type="range"] {
    height: 10px;   
}

.main-container .sidebar .filters .filter .filter-content input[type="range"],
.main-container .sidebar .filters .filter .filter-content input[type="range"]::-webkit-slider-runnable-track,
.main-container .sidebar .filters .filter .filter-content input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

.main-container .sidebar .filters .filter .filter-content input[type="range"]::-webkit-slider-runnable-track {

}

.main-container .sidebar .filters .filter .filter-content input[type="range"]::-webkit-slider-thumb {
    position: relative;
    background-color: #1878F1;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
}

.main-container .sidebar .filters .filter .filter-content input[type="range"]::-webkit-slider-thumb::before {
    position: absolute;
    content: '';
    height: 10px; 
    width: 500px; 
    left: -502px; 
    top: 8px; 
    background: #777;
}

.main-container .sidebar .filters .platform .filter-content .checkbox-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
} 

.main-container .sidebar .filters .platform .filter-content .checkbox-container .cb {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
}

.main-container .sidebar .filters .platform .filter-content .checkbox-container .cb .title p {
    font-weight: normal;
    font-size: 0.7em;
    text-align: left;
}

.main-container .sidebar .filters .platform .filter-content .checkbox-container .cb .input-container input {
    width: 17px;
    height: 17px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #ccc;
    appearance: none; /* Removes default styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    cursor: pointer;
}

.main-container .sidebar .filters .platform .filter-content .checkbox-container .cb .input-container input:checked {
    background-color: #1878f1;
}

.main-container .sidebar .filters .username .selected-usernames .container .selected-username span,
.main-container .sidebar .filters .keywords .selected-keywords .container .selected-keyword span,
.main-container .sidebar .filters .audio-id .selected-audio-ids .container .selected-audio-id span {
    color: #1878F1;
    font-size: 1em;
    cursor: pointer;
}

.main-container .sidebar .filters .username .selected-usernames .container,
.main-container .sidebar .filters .keywords .selected-keywords .container,
.main-container .sidebar .filters .audio-id .selected-audio-ids .container {
    width: 100%;
    display: flex;
    flex-direction: row; 
    justify-content:flex-start;
    flex-wrap: wrap;
    margin-top: 1vh;
}

.main-container .sidebar .filters .username .selected-usernames .container .selected-username,
.main-container .sidebar .filters .keywords .selected-keywords .container .selected-keyword,
.main-container .sidebar .filters .audio-id .selected-audio-ids .container .selected-audio-id {
    background-color: #fff;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
    margin-bottom: 1vh;
}

.main-container .sidebar .filters .followers .filter-content p {
    width: 100%;
    text-align: center;
    font-weight: bold;
    margin-top: 0.5vh;
    font-size: 1.1em;
}

.main-container .sidebar .filters .location .filter-content .menu-flags-select button {
    background-color: #fff; 
    border-radius: 15px;
    height: 5vh;
}

.main-container .sidebar .filters .location .filter-content .selected-locations .container,
.main-container .sidebar .filters .filter .filter-content .selected-niches .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    flex-wrap:wrap;
}

.main-container .sidebar .filters .location .filter-content .selected-locations .container .location,
.main-container .sidebar .filters .filter .filter-content .selected-niches .container .niche-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 27%;
    margin-left: 5%;
    margin-bottom: 1vh;
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    flex-wrap: wrap;
}

.main-container .sidebar .filters .filter .filter-content .selected-niches .container .niche-item {
    width: 35%;
}

.main-container .sidebar .filters .location .filter-content .selected-locations .container .location span, 
.main-container .sidebar .filters .niches .filter-content .selected-niches .container .niche-item span {
    font-size: 0.8em;
    color: #1878F1;
    cursor: pointer;
}

.main-container .sidebar .filters .location .filter-content .selected-locations .container .location .flag {

}

/* .main-container .sidebar .filters .location .filter-content .selected-locations .location,
.main-container .sidebar .filters .filter .filter-content .selected-niches .container .niche-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */

.main-container .research-container .results-table {
    width: 100%;
    height: 83vh;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
}

.main-container .research-container .results-table .title-row {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: flex-start;
    margin-top: 2vh;
    /* margin-bottom: 2vh; */
    margin-bottom: 1vh;
    padding-bottom: 1vh;
    border-bottom: 1px solid lightgray;
}

.main-container .research-container .results-table .title-row .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    cursor: pointer;
    
    /* width: 18%; */
    /* margin-right: 4%; */
    
}

.main-container .research-container .results-table .profile {
    width: 8.5%;
    margin-right: 4%;
}

.main-container .research-container .results-table .username {
    width: 12%;
    margin-right: 4%;
}

.main-container .research-container .results-table .followers {
    width: 8.5%;
    margin-right: 4%;
}

.main-container .research-container .results-table .location {
    width: 16.5%;
    margin-right: 4%;
}

.main-container .research-container .results-table .engagement {
    width: 12%;
    margin-right: 4%;
}

.main-container .research-container .results-table .niche {
    width: 8.5%;
    margin-right: 4%;
}

.main-container .research-container .results-table .price {
    
}

.main-container .research-container .results-table .title .icon {
    /* padding-right: 10px; */
    color: #1878F1;
    color: #83b6f8;
    margin-right: 5%;
}

.main-container .research-container .results-table .title-row .title .text {
    /* font-family: sans-serif; */
}

.main-container .research-container .results {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    overflow-x: hidden;
    overflow-y: auto;

    padding-top: 1vh;
    padding-left: 5%;
    padding-right: 5%;
}

.main-container .research-container .results .result {
    width: 31%; 
    min-width: 270px;
    height: 40vh;
    /* height: 60vh; */
    border: 2px solid gray;
    border-radius: 10px;
    margin-right: 2%;
    margin-bottom: 1vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.main-container .research-container .results .result .generated-container.generated-display-none-property {
    display: none;
}

.main-container .research-container .results .result.height-60 {
    height: 60vh;
}

.main-container .research-container .results .result .result-video {
    width: 100%;
    height: 35vh;
    border-bottom: 1px solid gray;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    color: #1878F1;
    font-size: 1.2em;
    font-weight: bold;
    text-decoration: underline;
}

.main-container .research-container .results .result .result-video iframe {
    width: 95%;
    height: 95%;
    border-radius: 15px;
}

.main-container .research-container .results .result .result-video p {
    cursor: pointer;
}

.main-container .research-container .results .result .result-video video {
    width: 100%;
    height: 100%;
}

.main-container .research-container .results .result .analytics-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    
    width: 100%;
    height: 5vh;
}

.analytics-container-border {
    border-bottom: 1px solid gray;
}

.main-container .research-container .results .result .analytics-container .analytics {
    width: 30%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.main-container .research-container .results .result .analytics-container .icon {
    color: #1878F1;
    margin-right: 5%;
}

.main-container .research-container .results .result .analytics-container .value {
    /* font-weight: bold; */
}


.main-container .research-container .results .result .generated-container {
    width: 100%;
    height: 20vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 5%;
    padding-right: 5%;
}

