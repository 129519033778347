/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* Header Styles */
  .header {
    background-color: #ffffff;
    padding: 20px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .navbar ul {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .navbar ul li {
    display: inline-block;
  }
  
  .navbar ul li a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
  }
  
  .cta-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cta-btn:hover {
    background-color: #0056b3;
  }
  
  /* Hero Section */
  .hero {
    background-color: #f9f9f9;
    padding: 80px 0;
    text-align: center;
  }
  
  .hero-text {
    margin-bottom: 40px;
  }
  
  .hero h1 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .hero p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .hero-image img {
    max-width: 100%;
  }
  
  /* Partners Section */
  .partners {
    padding: 40px 0;
    text-align: center;
  }
  
  .partner-logos {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .partner-logos li {
    list-style: none;
  }
  
  /* Features Section */
  .features {
    background-color: #ffffff;
    padding: 80px 0;
  }
  
  .features h2 {
    font-size: 28px;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .feature-list {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .feature-item {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
  }
  
  .feature-item h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  
  .feature-item p {
    font-size: 16px;
  }
  
  /* Testimonials Section */
  .testimonials {
    background-color: #f9f9f9;
    padding: 80px 0;
    text-align: center;
  }
  
  .testimonials h2 {
    font-size: 28px;
    margin-bottom: 40px;
  }
  
  .testimonial-item {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    margin: 0 auto;
    width: 50%;
  }
  
  .testimonial-item h4 {
    margin-top: 20px;
    font-size: 18px;
  }
  
  /* Footer Section */
  .footer {
    background-color: #333;
    color: white;
    padding: 40px 0;
    text-align: center;
  }
  
  .footer-links {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .footer-links li a {
    color: white;
    text-decoration: none;
  }
  
  .footer-links li a:hover {
    text-decoration: underline;
  }
  
  .footer p {
    font-size: 14px;
  }
  